import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  .custom-scroll::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #221e1e75;
  }

  .custom-scroll::-webkit-scrollbar
  {
    width: 6px;
    background-color: ${(props) => props.theme.scrollbar};
  }

  .custom-scroll::-webkit-scrollbar-thumb
  {
    background: ${(props) => props.theme.scrollbarThumb};
  }

  .swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: contain;
}

.MuiDialog-paperScrollPaper{
  background: #0000004f !important;
}

`

export const dreamsColor = {
  homeBackground: 'linear-gradient(45deg, rgb(50 0 200 / 70%), rgb(0 100 255 / 70%))',
  shadowBorderColor:'rgb(0 150 255)',
  backgroundPosition:'center bottom',
  backgroundAppBarCustom:'rgb(0 6 62 / 50%)',

  itemBackground: 'rgb(0 41 144 / 50%)',
  item:'rgb(25 7 73 / 30%)',
  itemSelect:'rgb(25 7 73 / 60%)',

  tableHead:'rgb(15 12 105 / 80%)',
  tableItem:'rgb(0 3 52 / 50%)',
  
  itemRankBackground:'linear-gradient(180deg,rgb(0 27 255 / 50%) 10.42%,rgb(3 23 164 / 50%) 85.94%)',
  itemRankShadow:'#cec0ff',

  btnBackground:'linear-gradient(180deg, #005ef2, #001d89)',
  btnShadow:'#011048',
  btnPush:'linear-gradient(rgb(35 65 111), rgb(15 19 35))',

  btn_secondary_bg:'linear-gradient(180deg,#ffffff,#a0a0a0)',
  btn_secondary_text:'#001d89',
  btn_secondary_text_over:'#fff',

  scrollbar:'#5a51f8',
  scrollbarThumb:'#019bff',

  textStyles : {
    small: {
      fontSize: '16px',
      fontWeight:'700'
    },
    medium: {
      fontSize: '24px',
      fontWeight:'900',
    },
    large: {
      fontSize: '32px',
      fontWeight:'900'
    },
    xlarge: {
      fontSize: '9vh',
      fontWeight:'900'
    }
  },

  buttons: {
    primary: {
      color: '#FFFFFF',
      background:'linear-gradient(180deg, #005ef2, #001d89)',
      '&:hover,&:focus': {
        background:'linear-gradient(#951916,#3c0302)',
        boxshadow: '0px 3px 4px 2px #220100'
      },
      '&:active': {
        boxShadow: 'inset 0 0 4px 2px #220100',
        background:'linear-gradient(#560f0d,#220100)'
      },
      '&:disabled': {
        boxShadow: '0px 3px 4px #220100',
        background: 'linear-gradient(180deg, #d27806 0%, #87500a 100%)',
        cursor: 'not-allowed',
        color: '#560f0d80'
      }
    },
    secondary: {
      color: '#001d89',
      background: 'linear-gradient(180deg, #FFFFFF 0%, #7D7D7D 100%)',
      '&:hover,&:focus': {
        background:'linear-gradient(rgb(109 109 109),rgb(0 0 0))',
        boxshadow: '0px 3px 4px 2px #220100',
        color:'white'
      },
      '&:active': {
        boxShadow: 'inset 0 0 4px 2px #220100',
        background:'linear-gradient(rgb(62 62 62),rgb(0 0 0))',
        color:'white'
        
      },
      '&:disabled': {
        boxShadow: '0px 3px 4px #220100',
        background: 'linear-gradient(180deg, #FFFFFF 0%, #7D7D7D 100%)',
        cursor: 'not-allowed',
        color: '#ffffff80'
      }
    }  
  }
}

export const generalColor = {
  homeBackground: 'rgb(120 0 0 / 90%)',
  shadowBorderColor: 'rgb(255 0 0 / 50%)',
  backgroundPosition:'center top',
  backgroundAppBarCustom:'rgba(220,0,0,0.5)',

  itemBackground: 'linear-gradient(180deg, rgb(255 0 0 / 20%) 0%, rgb(108 0 0 / 20%) 100%)',
  item:'rgb(240 0 0 / 30%)',
  itemSelect:'rgb(240 0 0 / 60%)',

  tableHead:'rgba(220, 0, 0, 0.5)',
  tableItem:'rgb(52 0 0 / 50%)',

  itemRankBackground:'linear-gradient(180deg, rgba(255, 0, 0, 0.5) 10.42%, rgba(164, 3, 3, 0.5) 85.94%)',
  itemRankShadow:'#ff9900',

  btnBackground:'linear-gradient(180deg, #d27806, #87500a)',
  btnShadow:'#220100',
  btnPush:'linear-gradient(180deg,#915406,#503006)',

  btn_secondary_bg:'linear-gradient(180deg,#ffffff,#a0a0a0)',
  btn_secondary_text:'#764534',
  btn_secondary_text_over:'#fff',

  scrollbar:'#5a1212',
  scrollbarThumb:'#b8860b',

  textStyles : {
    small: {
      fontSize: '16px',
      fontWeight:'700'
    },
    medium: {
      fontSize: '24px',
      fontWeight:'900',
    },
    large: {
      fontSize: '32px',
      fontWeight:'900'
    },
    xlarge: {
      fontSize: '54px',
      fontWeight:'900'
    }
  },
  
  buttons: {
    primary: {
      color: '#FFFFFF',
      background:'linear-gradient(180deg, #d27806 0%, #87500a 100%)',
      '&:hover,&:focus': {
        background:'linear-gradient(180deg,#af6200,#6a3f06)',
        boxshadow: '0px 3px 4px 2px #220100'
      },
      '&:active': {
        boxShadow: 'inset 0 0 4px 2px #220100',
        background:'linear-gradient(180deg,#af6200,#6a3f06)'
      },
      '&:disabled': {
        boxShadow: '0px 3px 4px #220100',
        background: 'linear-gradient(180deg, #d27806 0%, #87500a 100%)',
        cursor: 'not-allowed',
        color: '#560f0d80'
      }
    },
    secondary: {
      color: '#560F0D',
      background: 'linear-gradient(180deg, #FFFFFF 0%, #7D7D7D 100%)',
      '&:hover,&:focus': {
        background:'linear-gradient(180deg,#afafaf 0%,#5a5a5a 100%)',
        boxshadow: '0px 3px 4px 2px #220100'
      },
      '&:active': {
        boxShadow: 'inset 0 0 4px 2px #220100',
        background:'linear-gradient(180deg,#afafaf 0%,#5a5a5a 100%)'
      },
      '&:disabled': {
        boxShadow: '0px 3px 4px #220100',
        background: 'linear-gradient(180deg, #FFFFFF 0%, #7D7D7D 100%)',
        cursor: 'not-allowed',
        color: '#ffffff80'
      }
    }  
  }
}