import React from 'react'
import Text, { TextGold, TextGoldRepeat } from '../styledComponents/TextStyled'
import { CardWinnerContainer,ContainerText } from './CardWinner'
import { IconMaquinas } from './Icons'

export default function CardWinnerHotSeat(props) {

  return (
    <CardWinnerContainer>
      {props.confId &&
      <>
        <TextGold textStyle='xlarge'>FELICIDADES</TextGold>

        <ContainerText flexDirection='row'>
          <Text textStyle='xlarge'>Máquina: {props.machine === null  ? props.confId : props.machine}</Text>
          <IconMaquinas fontSize={'64px'} />
        </ContainerText>
        <ContainerText>
          <Text>ES LA POSICIÓN</Text>
          <TextGoldRepeat textStyle='xlarge'>¡GANADORA!</TextGoldRepeat>
        </ContainerText>
        {props.showWinner && (props.winnerName || props.winnerDocument) ?
          <ContainerText>
            <Text textStyle='xlarge'>{'CLIENTE: ' + props.winnerName}</Text>
            <Text textStyle='xlarge'>{'DNI: ' + props.winnerDocument}</Text>
          </ContainerText>
          :
          <>
          </>
        }
        {props.hijo}
      </>
      }
    </CardWinnerContainer>
  );
}
