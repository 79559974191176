import React, {useContext, useState} from "react"
import { useHistory, useParams } from "react-router"
import { Apis } from "../util/Apis"
import styled from "styled-components"
import ContainerBackgroundWrapper from "../components/Backgrounds"
import CustomLoading from "../components/CustomLoading"
import Confetti from 'react-confetti'
import useWindowSize from '../hooks/useWindowSize'
import CardWinnerHotSeat from "../components/CardWinnerHotseat"
import ButtonCustom from "../components/ButtonCustom"
import NoSorteos from "../components/NoSorteos"
import Text from "../styledComponents/TextStyled"
import useGet from "../hooks/useGet"
import {SiteContext} from "../context/SiteContext";

const ContenidoBotones = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(15vh - 2rem);
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.5s ease;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    height: calc(15vh - 1rem);
  }
`

const CongratHotSeatWinner = () => {
  const history = useHistory()
  const {id} = useParams()
  const {sorteosConf} = useContext(SiteContext);

  const [existWinner, setExistWinner] = useState(false)

  const size = useWindowSize();

  const {loading, data} = useGet({
    url: Apis.SORTEOS + '/sorteos/' + id + '/winner',
    initialValue: [],
    onSuccess: (data) => {
      if (data && data.confId > 0) setExistWinner(true)
    }
  })

  const confirmWinner = () => history.push('/sorteo-list')
  
  return (
    <ContainerBackgroundWrapper showBackgroundCard={false}>
      {!loading ?
        <>
          {existWinner ?
            <>
              <Confetti
                width={size.width}
                height={size.height}
                opacity={0.8}
              />
              <CardWinnerHotSeat
                winnerName={(data.firstName && data.lastName1) ? (data.firstName + ' ' + data.lastName1) : null}
                winnerDocument={data.document ? data.document : null}
                machine={data.machine}
                confId={data.confId}
                showWinner={sorteosConf?.showWinnerHotSeat}
              />

              <ContenidoBotones>
                <ButtonCustom onClick={() => history.push('/sorteo-animation-hotseat/' + id)} variant='secondary'>REINICIAR</ButtonCustom>
                <ButtonCustom onClick={confirmWinner}>CONFIRMAR</ButtonCustom>
              </ContenidoBotones>
            </>
            :
            <NoSorteos>
              <Text textStyle='small'>No se encontró cupones participantes</Text>
              <ButtonCustom onClick={() => history.push('/sorteo-list')}>VOLVER</ButtonCustom>
            </NoSorteos>
          }
        </>
        :
        <CustomLoading />
      }
    </ContainerBackgroundWrapper>
  )
}

export default CongratHotSeatWinner;
