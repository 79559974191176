import React, {useState, useEffect, useContext} from "react"
import styled from "styled-components"
import defaultBackground from "../assets/images/fondoCasino.jpg";
import dreamsBackground from "../assets/backgrounds/Fondo-AppDreams-2.jpg"
import * as MdIcons from 'react-icons/md'
import {Dialog, DialogContent, Slide} from "@material-ui/core"
import Publicidad from "./Publicidad"
import {SiteContext} from "../context/SiteContext";


export const ContainerBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${(props) => props.theme.backgroundPosition};
  position: relative;
  z-index: 1;
`

const Card = styled.div`
  width: calc(100% - 4rem);
  height: calc(100vh - 4rem);
  background: ${(props) => props.theme.itemBackground};
  border-radius: 16px;
  border: 1px solid ${(props) => props.theme.itemBackground};
  overflow: hidden;
  box-shadow: 0 0 10px 10px ${(props) => props.theme.shadowBorderColor};
  transition: all 0.5s ease;
  @media (max-height: 768px) {
    transition: all 0.5s ease;
    width: calc(100% - 2rem);
    height: calc(100vh - 2rem);
  }
`
const LogoBack = styled.div`
  color: #ffffff80;
  bottom: 4px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  transition: all 0.5s ease;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    font-size: 10px;
  }
`

export const BackgroundHome = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  flex-wrap: wrap;
  background-image: url(${props => props.img});
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left center;
  display: flex;
  background-attachment: fixed;
`

const IconsTop = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  color: white;
  top: 48px;
  right: 48px;
  z-index: 2;
  @media (max-height: 600px) {
    transition: all 0.5s ease;
    top: 32px;
    right: 32px;
  }
`

const ButtonPub = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
  background: #fff;
  border: 0;
  padding: 8px 10px 4px;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 50%);
`

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContainerBackgroundWrapper = ({children, showBackgroundCard = true}) => {

  let homeBackground = defaultBackground
  if (window.location.host === 'dreams.sorteos.wargos.cloud') {
    homeBackground = dreamsBackground
  }

  const {ads} = useContext(SiteContext)

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [mostrarPub, setMostrarPub] = useState(false)

  useEffect(() => {
    if (ads.length > 0) {
      setMostrarPub(true)
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ContainerBackground img={homeBackground}>
      <Card>
        {showBackgroundCard && mostrarPub &&
          <IconsTop>
            <MdIcons.MdCampaign fontSize={32} cursor={'pointer'} onClick={handleClickOpen}/>
          </IconsTop>
        }
        {children}
      </Card>
      <LogoBack>Powered by <strong>Wargos Tec</strong></LogoBack>

      <Dialog open={open} TransitionComponent={Transition} keepMounted fullScreen onClose={handleClose}>
        <DialogContent style={{padding: '0px'}}>
          <ButtonPub style={{cursor: 'pointer'}}>
            <MdIcons.MdClose fontSize={32} onClick={handleClose}/>
          </ButtonPub>
          <Publicidad/>
        </DialogContent>
      </Dialog>

    </ContainerBackground>
  )
}

export default ContainerBackgroundWrapper
